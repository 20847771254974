<template>
  <v-layout justify="center">
    <v-dialog v-model="shower" max-width="1500" persistent>
      <v-card class="px-10 py-5">
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn class="mb-5" small icon @click="closedialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <v-card-text style="height:1600px">
        <!-- ค้นหา -->
        <span :style="headerPage">{{ $t("dcmPage.conditionFile") }}</span>
        <v-form class="px-lg-14">
          <v-container>
            <v-layout wrap>
              <!-- <v-flex xs12 sm6 md6 lg6 class="pa-1">
                <v-text-field
                  dense
                  outlined
                  clearable
                  required
                  v-model="buyer_taxid"
                  prepend-icon="mdi-domain"
                  :color="color.theme"
                  :label="$t('resendfile.buyer_taxid')"
                  :error-messages="buyer_taxidError"
                  @input="$v.buyer_taxid.$touch()"
                  @blur="$v.buyer_taxid.$touch()"
                ></v-text-field>
              </v-flex> -->
              <v-flex lg12 class="pa-1">
                <v-text-field
                  dense
                  outlined
                  clearable
                  v-model="document_no"
                  :color="color.theme"
                  :label="$t('resendfile.document_no')"
                  prepend-icon="format_list_numbered"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm12 md6 lg6 class="pa-1">
                <v-dialog
                  ref="dialogFromDateResend"
                  v-model="modalFromDateResend"
                  :return-value.sync="fromDateResend"
                  persistent
                  full-width
                  width="290px"
                  :color="color.theme"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      clearable
                      :color="color.theme"
                      :label="$t('resendfile.fromdate')"
                      v-model="fromDateResend"
                      @click:prepend="modalFromDateResend = true"
                      prepend-icon="event"
                      v-on="on"
                      readonly
                      outlined
                      dense
                      @click:clear="toDateResend = ''"
                      class="my-hint-style pa-1 pt-0 pb-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDateResend"
                    scrollable
                    :locale="$t('default')"
                    @input="changeFromDateResend()"
                    :max="toDateResend"
                    :color="color.theme"
                  >
                    <v-spacer></v-spacer>
                    <v-btn dark color="error" @click.stop="modalFromDateResend = false">
                      {{ $t("resendfile.closefromdateresend") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm12 md6 lg6 class="pa-1">
                <v-dialog
                  ref="dialogTodateupload"
                  v-model="modalToDateResend"
                  :return-value.sync="toDateResend"
                  persistent
                  full-width
                  width="290px"
                  :color="color.theme"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      clearable
                      :color="color.theme"
                      :label="$t('resendfile.todate')"
                      v-model="toDateResend"
                      @click:prepend="modalToDateResend = true"
                      prepend-icon="event"
                      v-on="on"
                      readonly
                      outlined
                      dense
                      class="my-hint-style pa-1 pt-0 pb-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDateResend"
                    scrollable
                    :locale="$t('default')"
                    @input="$refs.dialogTodateupload.save(toDateResend)"
                    :min="fromDateResend"
                    :color="color.theme"
                  >
                    <v-spacer></v-spacer>
                    <v-btn dark color="error" @click.stop="modalToDateResend = false">
                      {{ $t("resendfile.closefromdateresend") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>                
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex lg12 d-flex justify-center>
                <v-btn
                  class="ma-1 white--text"
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="getDataResend()"
                >
                  <v-icon left>search</v-icon>
                  &nbsp; {{ $t("myinboxPage.search") }}
                </v-btn>                
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>

        <div v-if="resolutionScreen <= 500">
          <v-list two-line class="mb-6 pb-6 elevation-1" v-if="rootfile.length !== 0">
            <v-list-item v-for="item in rootfile" :key="item.title" style="cursor: pointer;">
              <v-list-item-avatar>
                <v-icon large :color="seticon(item.data_type)[1]">
                  {{ seticon(item.data_type)[0] }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.filename"></v-list-item-title>
                <v-list-item-subtitle v-text="item.document_no"></v-list-item-subtitle>
                <div>
                  <v-chip
                    x-small
                    dark
                    :color="
                      item.status === 'pending'
                        ? '#ffcc00'
                        : item.status === 'reject'
                        ? 'red'
                        : item.status === 'unsend'
                        ? '#ff8533'
                        : item.status === 'request'
                        ? 'primary'
                        : item.status === 'request_delete_log'
                        ? '#FF0000'
                        : item.status === 'pending_receiver_stoage_full'
                        ? '#ffcc00'
                        : item.status === 'duplicate'
                        ? 'primary'
                        : 'success'
                    "
                  >{{ item.status }}</v-chip>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu>
                  <template v-slot:activator="{ on }" >
                    <v-btn class="elevation-0" fab small v-on="on" outlined>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- รายละเอียดไฟล์ -->
                    <v-list-item @click="(opendialog = true), (newdata = item)">
                      <v-list-item-icon>
                        <v-icon>mdi-information</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("myinboxPage.Filedetails") }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- ปุ่มส่งซ้ำ -->
                    <v-list-item @click="resendfile(item)" :disabled="item.status_trash_receiver === 'ผู้ขายลบไปแล้ว'">
                      <v-list-item-icon>
                        <v-icon>mdi-send</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("resendfile.resend") }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list v-else class="elevation-1">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <div v-else>
          <!-- ไอคอนเมนู -->
          <v-layout class="mb-2">
            <div class="ml-2">
              <div v-if="statusmutipleresend === false" class="pt-0">
                <v-btn
                  class="ma-1 white--text"
                  rounded
                  fab
                  small
                  :color="color.theme"
                  @mouseover="show_menu = true"
                  @mouseleave="show_menu = false"
                  @click="statusmutipleresend = true, show_menu = false"
                >
                  <v-icon>apps</v-icon>&nbsp;
                  <v-badge v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-24px;"
                    :value="show_menu"
                    :content="$t('balloon.menu')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge v-else
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="show_menu"
                    :content="$t('balloon.menu')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
              </div>
              <div v-else class="pt-2">
                <v-btn 
                  class="white--text ma-1"
                  fab 
                  small 
                  :color="'primary'" 
                  @mouseover="show_resend = true"
                  @mouseleave="show_resend = false"
                  @click="multipleresendfile()"
                >
                  <v-icon>mdi-send</v-icon>
                  <v-badge v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="show_resend"
                    :content="$t('balloon.resend')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge v-else
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="show_resend"
                    :content="$t('balloon.resend')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
                <v-btn
                  @mouseover="close_menu = true"
                  @mouseleave="close_menu = false"
                  fab
                  small
                  :color="color.theme"
                  :dark="color.darkTheme"
                  class="ma-1"
                  @click="(statusmutipleresend = false), clearmultipleresend(), close_menu = false"
                >
                  <v-icon>clear</v-icon>
                  <v-badge v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-20px;"
                    :value="close_menu"
                    :content="$t('balloon.clear')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge v-else
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="close_menu"
                    :content="$t('balloon.clear')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>              
              </div>
            </div>
          </v-layout>

          <!-- ตาราง -->
          <div>
            <v-data-table
              v-model="filemultipleresend"
              :headers="headers"
              :items="rootfile"
              item-key="id"
              class="elevation-1"
              hide-default-footer
              :single-select="false"
              :show-select="statusmutipleresend"
              :page.sync="page"
              :items-per-page="size"
              @page-count="pageCount = $event"
            >
              <template v-slot:[`header.data_type`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>          
              <template v-slot:[`header.filename`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>
              <template v-slot:[`header.document_no`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>
              <template v-slot:[`header.document_date`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>
              <template v-slot:[`header.document_type`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>
              <template v-slot:[`header.receiver_name`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>
              <template v-slot:[`header.cre_dtm`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>
              <template v-slot:[`header.status_trash_receiver`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>            
              <template v-slot:[`header.optional`]="{ header }">
                <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
              </template>
              <template v-slot:item="props">
                <tr>
                  <td class="text-center" v-show="statusmutipleresend">
                    <v-checkbox v-model="filemultipleresend" :value="props.item" v-if="props.item.status_trash_receiver !== 'ผู้ขายลบไปแล้ว'"/>
                  </td>
                  <td width="5%" class="text-center">
                    <v-icon large :color="seticon(props.item.data_type)[1]">
                      {{ seticon(props.item.data_type)[0] }}
                    </v-icon>
                    <v-chip
                      x-small
                      dark
                      :color="
                        props.item.status === 'pending'
                          ? '#ffcc00'
                          : props.item.status === 'reject'
                          ? 'red'
                          : props.item.status === 'unsend'
                          ? '#ff8533'
                          : props.item.status === 'request'
                          ? 'primary'
                          : props.item.status === 'request_delete_log'
                          ? '#FF0000'
                          : props.item.status === 'pending_receiver_stoage_full'
                          ? '#ffcc00'
                          : props.item.status === 'duplicate'
                          ? 'primary'
                          : 'success'
                      "
                    >{{ props.item.status }}</v-chip>
                  </td>
                  <td width="30%" class="text-left">{{ props.item.filename }}</td>
                  <td width="10%" class="text-left">{{ props.item.document_no }}</td>
                  <td width="10%" class="text-center">{{ props.item.document_date }}</td>
                  <td width="10%" class="text-center">{{ props.item.document_type }}</td>
                  <td width="10%" class="text-left">{{ props.item.receiver_name}}</td>
                  <td width="10%" class="text-center">{{ props.item.cre_dtm }}</td>
                  <td width="10%" class="text-center">{{ props.item.status_trash_receiver }}</td>
                  <td width="5%" class="text-left">
                    <v-menu>
                      <template v-slot:activator="{ on }" >
                        <v-btn class="elevation-0" fab small v-on="on" outlined>
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <!-- รายละเอียดไฟล์ -->
                        <v-list-item @click="(opendialog = true), (newdata = props.item)">
                          <v-list-item-icon>
                            <v-icon>mdi-information</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t("myinboxPage.Filedetails") }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <!-- ปุ่มส่งซ้ำ -->
                        <v-list-item @click="resendfile(props.item)" :disabled="props.item.status_trash_receiver === 'ผู้ขายลบไปแล้ว'">
                          <v-list-item-icon>
                            <v-icon>mdi-send</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t("resendfile.resend") }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="text-xs-center">
          <br />
          <v-pagination
            v-model="page"
            :length="pageCount"
            :color="color.theme"
            :total-visible="10"
            @input="clickpagination()"
          ></v-pagination>
        </div>
        </v-card-text>

        <dialogdetailresend :show="opendialog" @closedialog="opendialog = false" :fileresend="newdata"></dialogdetailresend>

      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from "sweetalert2/dist/sweetalert2.js";
import format from "date-fns/format";

const dialogdetailresend = () => import("@/components/optional/dialog-detailresend");
// import { validationMixin } from "vuelidate";
// import { required, maxLength, email } from "vuelidate/lib/validators";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show"],
  // mixins: [validationMixin],
  // validations: {
  //   buyer_taxid: { required }
  // },
  components: {
    dialogdetailresend
  },
  data() {
    return {
      headers: [
      {
        width: "5%",
        text: "#",
        value: "data_type",
        align: "center",
      },
      {
        width: "30%",
        text: "resendfile.filename",
        value: "filename",
        align: "center",
      },
      {
        width: "10%",
        text: "resendfile.document_no",
        value: "document_no",
        align: "center",
      },
      {
        width: "10%",
        text: "resendfile.document_date",
        value: "document_date",
        align: "center",
      },
      {
        width: "10%",
        text: "resendfile.document_type",
        value: "document_type",
        align: "center",
      },
      {
        width: "10%",
        text: "resendfile.receiver_name",
        value: "receiver_name",
        align: "center",
      },
      {
        width: "10%",
        text: "resendfile.cre_dtm",
        value: "cre_dtm",
        align: "center",
      },
      {
        width: "10%",
        text: "resendfile.status_trash_receiver",
        value: "status_trash_receiver",
        align: "center",
      },      
      {
        width: "5%",
        text: "resendfile.optional",
        value: "optional",
        align: "center",
      },         
      ],
      document_no: "",
      fromDateResend: "",
      toDateResend: "",
      modalFromDateResend: false,
      modalToDateResend: false,
      loaddataprogress: false,
      statusmutipleresend: false,
      show_menu: false,
      show_resend: false,
      close_menu: false,
      showFileDetail: false,
      opendialog: false,
      rootfile: [],
      filemultipleresend: [],
      newdata: {},
      // buyer_taxid: "",
      totalfile: 0,
      size: 20,
      page: 1,
    }
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
    },
    pageCount: {
      get() {
        let l = this.totalfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },    
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    // buyer_taxidError() {
    //   const errors = [];
    //   if (!this.$v.buyer_taxid.$dirty) return errors;
    //   !this.$v.buyer_taxid.required && errors.push("โปรดระบุเลขประจำตัวภาษีผู้ขาย / Please fill buyer Tax ID (TH)");
    //   return errors;
    // },
  },
  methods: {
    changeFromDateResend() {
      this.$refs.dialogFromDateResend.save(this.fromDateResend);
      this.toDateResend = format(new Date(), "yyyy-MM-dd");
    },
    formatdate(_date) {
      if (_date === "" || _date === null || _date === undefined) {
        return "-";
      } else {
        let date = `${_date.slice(6,8)}/${_date.slice(4,6)}/${_date.slice(0,4)}`
        return date;
      }
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === null || _datetime === undefined) {
        return "-";
      } else {
        let datetime = `${_datetime.slice(6,8)}/${_datetime.slice(4,6)}/${_datetime.slice(0,4)}` + " " + 
                       `${_datetime.slice(8,10)}:${_datetime.slice(10,12)}:${_datetime.slice(12,14)}`;      
        return datetime;
      }
    },
    clickpagination() {
      this.getDataResend();
    },
    async getDataResend() {
      this.loaddataprogress = true;
      this.totalfile = 0;
      this.rootfile = [];
      let offset = this.size * (this.page - 1);
      let payload
      payload = {
        sellerTaxId: this.dataAccountActive.taxid === null ? "" : this.dataAccountActive.taxid,
        document_no: this.document_no === null ? "" : this.document_no,
        cre_dtm_start: this.fromDateResend === null ? "" : this.fromDateResend,
        cre_dtm_end: this.toDateResend === null ? "" : this.toDateResend,
        order_by: "",
        sort_by: "asc",
        limit: this.size.toString(),
        offset: offset.toString(),
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/outbox_for_resend", payload, {
          headers: { Authorization: auth.code },
        })
        .then((res) => {
          console.log("res", res);
          if (res.data.status === "OK") {
            for (let i = 0; i < res.data.data.length; i++) {
              let dataResendFile = {};
              dataResendFile["id"] = res.data.data[i]["id"];
              dataResendFile["data_type"] = res.data.data[i]["data_type"];
              dataResendFile["status"] = res.data.data[i]["status"];
              dataResendFile["filename"] = res.data.data[i]["filename"];
              dataResendFile["document_no"] = res.data.data[i]["document_no"];
              dataResendFile["document_date"] = this.formatdate(res.data.data[i]["document_date"]);
              dataResendFile["document_type"] = res.data.data[i]["document_type"];
              dataResendFile["sender_name"] = res.data.data[i]["sender_name"];
              dataResendFile["receiver_name"] = res.data.data[i]["receiver_name"];
              dataResendFile["cre_dtm"] = this.formatdatetime(res.data.data[i]["cre_dtm"]);
              dataResendFile["status_trash_receiver"] = res.data.data[i]["trash_receiver"] === 'DELETE' ? 'ผู้ขายลบไปแล้ว' : 'ผู้ขายยังไม่ได้รับ';                        
              this.rootfile.push(dataResendFile);
            }
            console.log('this.rootfile',this.rootfile);
            this.totalfile = res.data.total;
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    async resendfile(item) {
      let payload = {
        inbox_id: [item.id]
      };
      let auth = await gbfGenerate.generateToken();
      // console.log("auth", auth);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/resend/inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((res) => {
          console.log("res_resendfile", res);
          if (res.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Resend File Success",
            });
            this.clearmultipleresend();
            this.getDataResend();
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("resendfile.msg.apierror"),
            });
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: this.$t("resendfile.msg.apierror"),
          });
          console.log(error);
        });
    },    
    async multipleresendfile() {
      let multipleresend = [];
      multipleresend = this.filemultipleresend.filter(fileresend => fileresend.status_trash_receiver !== 'ผู้ขายลบไปแล้ว')
      if (multipleresend.length < 1) {
        Toast.fire({
          type: "warning",
          title: this.$t("resendfile.msg.nofile"),
        });
      } else {
        let filedata = [];
        for (let i = 0; i < multipleresend.length; i++) {
          filedata.push(multipleresend[i]["id"]);
          console.log("++",multipleresend[i]);
        }
          console.log(filedata);
          let payload = {
            inbox_id: filedata
          };
          let auth = await gbfGenerate.generateToken();
          // console.log("auth", auth);
          await this.axios
            .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/resend/inbox", payload, {
              headers: { Authorization: auth.code },
            })
            .then((res) => {
              console.log("res_multipleresendfile", res);
              if (res.data.status === "OK") {
                Toast.fire({
                  icon: "success",
                  title: "Resend File Success",
                });
                this.clearmultipleresend();
                this.getDataResend();
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("resendfile.msg.apierror"),
                });
              }
              this.clearmultipleresend();
            })
            .catch((error) => {
              Toast.fire({
                icon: "error",
                title: this.$t("resendfile.msg.apierror"),
              });
              console.log(error);
            });
      }
    },
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else {
        dataicon = ["mdi-file", "black"];
      }
      return dataicon;
    },    
    clearmultipleresend() {
      this.filemultipleresend.splice(0, this.filemultipleresend.length);
      this.statusmutipleresend = false;
    },
    closedialog() {
      // this.buyer_taxid = "";
      this.document_no = "";
      this.fromDateResend = "";
      this.toDateResend = "";
      this.clearmultipleresend();
      this.totalfile = 0;
      this.rootfile = [];
      this.$emit('closedialog')
    }
  },
}
</script>
